import styled from 'styled-components';

import { TrackedLink } from 'components/Link';
import { Link } from 'components/RouterLink';
import {
  colors,
  cssBreakpoints,
  gutterWidth,
  maxWidth,
  transition,
} from 'theme/theme';

export const PageFooterHolder = styled.footer<{
  $showDivider: boolean;
}>`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: ${maxWidth[3]}px;
  max-width: 100%;
  padding-left: ${gutterWidth / 2}px;
  padding-right: ${gutterWidth / 2}px;
  padding-bottom: 20px;

  ${(props) => props.$showDivider && `padding-top: 70px;`}

  @media screen and (min-width: ${cssBreakpoints.mdUp}) {
    padding-left: ${gutterWidth}px;
    padding-right: ${gutterWidth}px;
  }

  @media print {
    display: none;
  }
`;

export const PageFooterTrackedSocialLink = styled(TrackedLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: ${colors.mediumSkyBlue};
  transition: ${transition};

  &:hover,
  &:focus {
    background: ${colors.hoverBlue};
  }
`;

export const PageFooterSectionsAndLogoMissionHolder = styled.div<{
  $showDivider: boolean;
}>`
  display: flex;
  padding-top: 70px;
  ${(props) =>
    props.$showDivider && `border-top: 1px solid ${colors.selectionGrey};`}

  @media all and (max-width: ${cssBreakpoints.lgDown}) {
    flex-direction: column;
  }
`;

export const PageFooterSectionsHolder = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;

  @media all and (max-width: ${cssBreakpoints.mdDown}) {
    justify-content: center;
  }

  @media all and (width <= 400px) {
    flex-direction: column;
    align-items: center;
    text-align: center;

    & > *:not(:last-child) {
      margin-bottom: 30px;
    }
  }
`;

export const PageFooterSectionHolder = styled.section<{
  $desktopOnly: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 150px;
  flex-shrink: 0;

  @media all and (max-width: ${cssBreakpoints.mdDown}) {
    display: ${(props) => (props.$desktopOnly ? 'none' : undefined)};
  }
`;

export const PageFooterSectionTitle = styled.h4`
  font-weight: normal;
  color: ${colors.mediumContentGrey};
  font-size: 20px;
  margin: 0;
`;

export const PageFooterSectionLinksHolder = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media all and (width <= 400px) {
    gap: 10px;
  }
`;

export const PageFooterSectionLink = styled(Link)`
  font-size: 16px;
  color: ${colors.lightContentGrey};

  &:not(:hover) {
    text-decoration: none;
  }
`;

export const PageFooterLogoMissionHolder = styled.section<{
  $showDivider: boolean;
}>`
  display: flex;
  flex-direction: column;
  flex-basis: 25%;

  @media all and (max-width: ${cssBreakpoints.lgDown}) {
    align-items: center;
    margin-top: 30px;
    padding-left: ${gutterWidth}px;
    padding-right: ${gutterWidth}px;
    padding-top: 30px;
    border-top: 1px solid ${colors.selectionGrey};
  }
`;

export const PageFooterLogoLink = styled(Link)`
  margin-bottom: 24px;

  @media all and (max-width: ${cssBreakpoints.lgDown}) {
    display: none;
  }
`;

export const PageFooterMissionStatement = styled.p`
  font-size: 16px;
  line-height: 28.5px;
  margin: 0;
  color: ${colors.lightContentGrey};

  @media all and (max-width: ${cssBreakpoints.lgDown}) {
    text-align: center;
    width: 100%;
  }
`;

export const PageFooterSocialLinksHolder = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  @media all and (max-width: ${cssBreakpoints.lgDown}) {
    margin-top: 40px;
  }

  @media all and (min-width: ${cssBreakpoints.lgUp}) {
    margin-top: 40px;
  }
`;

export const PageFooterBottom = styled.div`
  display: flex;
  margin-top: 40px;

  @media all and (max-width: ${cssBreakpoints.mdDown}) {
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    align-items: center;
    justify-content: space-between;
  }
`;

export const PageFooterCopyright = styled.div`
  font-size: 16px;
  color: ${colors.lightContentGrey};

  @media all and (min-width: ${cssBreakpoints.lgUp}) {
    flex-basis: 25%;
  }
`;
